import * as React from 'react'
import { AppBar, Toolbar, Typography } from "@mui/material";
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import Box from '@mui/material/Box';
import {
  Link,
  Button,
} from "gatsby-theme-material-ui";
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({

});

const Layout = ({ pageTitle, children }) => {
  return (
    <ThemeProvider theme={theme}>
    <div>
      <GlobalStyles styles={
        {
          ul: { margin: 0, padding: 0, listStyle: 'none' },
          'div.MuiFormControl-root': { 'margin-top': 10 }
        }
      } />
      <CssBaseline />
      <title>{pageTitle}</title>
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
      >
        <Toolbar sx={{ flexWrap: 'wrap' }}>
          <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
            <Button to="/">Referee Clinic</Button>
          </Typography>
          <nav>
            <Button to="/newofficial"  sx={{ my: 1, mx: 1.5 }}>
              New Officials
            </Button>
            <Button to="/videos"  sx={{ my: 1, mx: 1.5 }}>
              Videos
            </Button>
            <Button to="/staff"  sx={{ my: 1, mx: 1.5 }}>
              Staff
            </Button>
          </nav>
        </Toolbar>
      </AppBar>
      <main>
        <Box>
          {children}
        </Box>
      </main>
    </div>
    </ThemeProvider>
  )
}
export default Layout
